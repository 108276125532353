import { useMicroCopy } from "./MicroCopy";
import { useFormattedDate } from "./FormattedString";
import { createMediaTextCta } from "./StoryblokSchema";

export function getCollectionNews(newsList, newsData, newsHub) {
  const newsMTC = newsList?.slice(0, 4).map((item) => {
    const publishedDate = newsData?.isFacilityNews
      ? item.content?.date
      : item.content?.overview_published_date;
    const date = publishedDate ? useFormattedDate(publishedDate) : null;
    const description = newsData?.isFacilityNews
      ? item.content?.body?.content?.filter(
          (item) => item.type === "paragraph"
        )[0]
        ? item.content?.body?.content?.filter(
            (item) => item.type === "paragraph"
          )[0]
        : item.content?.body
      : item.content?.overview_description;

    return createMediaTextCta({
      _uid: item.content._uid,
      text: item.content?.pdf_file?.filename?.length
        ? [description]
        : [date, description],
      filename: newsData?.isFacilityNews
        ? item.content?.photos[0]?.asset?.filename
        : item.content?.overview_picture?.filename,
      title: newsData?.isFacilityNews
        ? item.content.title
        : item.content.overview_title,
      ctaUrl: item.content?.pdf_file?.filename?.length ? null : item.full_slug,
      ctaLabel: item.content?.pdf_file?.filename?.length
        ? useMicroCopy("news.magazine.cta.label", "")
        : useMicroCopy("collectionnews.cta.label", ""),
      isCtaPlain: true,
      isFile: item.content?.pdf_file?.filename?.length > 0,
      isMagazine: item.content?.pdf_file?.filename?.length > 0,
      magazineName: item.content?.magazine_name,
      pdfFileUrl: item.content?.pdf_file?.filename?.length
        ? item.content?.pdf_file?.filename
        : null,
    } as never);
  });

  if (newsList?.length && newsData?.showAllCard && newsHub) {
    const seeAllNews = createMediaTextCta({
      _uid: newsHub?.content._uid,
      text: useMicroCopy("collectionnews.view.all.news.text", ""),
      title: useMicroCopy("collectionnews.view.all.news.title", ""),
      ctaLabel: useMicroCopy("collectionnews.view.all.news.cta.label", ""),
      isSpecial: true,
      ctaUrl: newsHub?.full_slug,
    } as never);
    newsMTC?.push(seeAllNews);
  }
  return newsMTC;
}
